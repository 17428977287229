<template>
  <div class="member-index">
    <!-- <el-tabs type="card" v-model="type" @tab-click="handleTabChange">
      <el-tab-pane label="全部" name=""></el-tab-pane>
      <el-tab-pane label="充值" name="topup"></el-tab-pane>
      <el-tab-pane label="购买" name="buy"></el-tab-pane>
      <el-tab-pane label="收入" name="income"></el-tab-pane>
    </el-tabs> -->
    <div class="search-wrapper">
      <div></div>
      <!-- <el-form inline>
        <el-form-item label="时间范围">
          <el-radio v-model="quickTime" :label="15" @change="handleQuickChange(15)">最近15天</el-radio>
          <el-radio v-model="quickTime" :label="90" @change="handleQuickChange(90)">最近三个月</el-radio>
          <el-date-picker
            v-model="pickTime"
            type="month"
            :picker-options="options"
            placeholder="选择查询时间"
            @change="handlePickerChange"
            :clearable="false"
          ></el-date-picker>
        </el-form-item>
      </el-form> -->
      <div class="xf-wrapper">
        <el-date-picker
          @change="handlePickerChange"
          type="daterange"
          :clearable="false"
          v-model="pickTime"
          :picker-options="pickerOptions"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
        <!-- <p v-html="getAmount"></p>
        <el-input
          placeholder="请输入订单号"
          v-model="searchForm.orderId"
          style="width: 200px;"
          @change="handleSearch"
        ></el-input> -->
      </div>
    </div>
    <el-table border align="center" :data="list">
      <el-table-column
        label="小区名"
        align="center"
        width="180"
        prop="name"
      ></el-table-column>
      <el-table-column
        label="类别"
        align="center"
        width="100"
        prop="TypeName"
      ></el-table-column>
      <el-table-column
        label="金额"
        align="center"
        width="100"
        prop="money"
      ></el-table-column>
      <!-- <el-table-column label="查看数" align="center" width="80" prop="check"></el-table-column> -->
      <el-table-column
        label="下载数"
        align="center"
        width="80"
        prop="download"
      ></el-table-column>
      <el-table-column label="状态" align="center" width="80" prop="state">
        <template slot-scope="scope">
          <span v-if="scope.row.state === 0">审核中</span>
          <span v-if="scope.row.state === 1">已通过</span>
          <span v-if="scope.row.state === 2">已拒绝</span>
        </template>
      </el-table-column>
      <el-table-column
        label="上架状态"
        align="center"
        width="80"
        prop="putaway"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.putaway === 'true'">已上架</span>
          <span v-if="scope.row.putaway === 'false'">已下架</span>
          <!-- <span v-if="scope.row.state === 2">已拒绝</span> -->
        </template>
      </el-table-column>
      <el-table-column label="地区" align="center" width="120" prop="remark">
        <template slot-scope="scope">
          <span>{{ scope.row.AreaName }}/{{ scope.row.SiteName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="上传时间"
        align="center"
        width="160"
        prop="CreationTime"
      ></el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        align="center"
        width="180"
        prop="operate"
      >
        <template slot-scope="scope">
          <div style="text-align: left">
            <el-button @click="download(scope.row.url)">下载</el-button>
            <el-button
              v-if="scope.row.state === 1"
              @click="changeState(scope.row.id, scope.row.ISprivacy)"
              >{{
                scope.row.ISprivacy === "true" ? "隐藏联系" : "公开联系"
              }}</el-button
            >
            <el-button
              v-if="scope.row.state === 2 || scope.row.state === 0"
              @click="handleEdit(scope.row)"
              >编辑</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :page-size="searchForm.pagenum"
      :current-page.sync="searchForm.page"
      :total="total"
      @current-change="handleCurrentChange"
    ></el-pagination>
    <el-dialog
      :visible.sync="editVisible"
      title="编辑"
      center
      width="800px"
      destroy-on-close
    >
      <upload
        v-if="editVisible"
        @confirm="onDialogConfirm"
        @close="editVisible = false"
        :data="editForm"
      ></upload>
    </el-dialog>
  </div>
</template>

<script>
import { getUploadList, changeUploadState } from "@/api";
import moment from "moment";
import upload from "@/components/upload";
export default {
  components: {
    upload,
  },
  data() {
    return {
      options: {
        disabledDate(val) {
          return val > Date.now();
        },
      },
      pickerOptions: {
        disabledDate(val) {
          return (
            val < moment("2022-01-01 00:00:00").valueOf() ||
            val > moment().endOf("day")
          );
        },
      },
      quickTime: 15,
      pickTime: [moment().subtract(15, "day").valueOf(), moment().valueOf()],
      type: "",
      list: [],
      total: 0,
      amount: 0,
      searchForm: {
        searchKey: "",
        page: 1,
        pagenum: 10,
        begin: "",
        end: "",
      },
      editForm: {},
      editVisible: false,
    };
  },
  mounted() {
    this.searchForm.begin = moment()
      .subtract(15, "day")
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss");
    this.searchForm.end = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
    this.getData();
  },
  computed: {
    getAmount() {
      if (!this.type || !this.amount) return "";
      switch (this.type) {
        case "coinConsume":
          return `消费 <span class='amount'>${this.amount}</span>硬币`;
        case "coinIncome":
          return `收入 <span class='amount'>${this.amount}</span>硬币`;
        case "coinRecharge":
          return `充值 <span class='amount'>${this.amount}</span>硬币`;
        case "scoreConsume":
          return `消耗 <span class='amount'>${this.amount}</span>积分`;
        case "scoreIncome":
          return `获得 <span class='amount'>${this.amount}</span>积分`;
        default:
          return ``;
      }
    },
  },
  methods: {
    onDialogConfirm() {
      this.editVisible = false;
      this.getData();
    },
    handleEdit(row) {
      this.editForm = JSON.parse(JSON.stringify(row));
      this.editVisible = true;
    },
    download(url) {
      let a = document.createElement("a");
      a.style.display = "none";
      a.href = this.$globalConfig.VUE_APP_IMAGE_URL + url;
      document.body.appendChild(a);
      // a.target = "_blank";
      // a.download = fileName + '.zip';
      a.click();
      document.body.removeChild(a);
    },
    async getData() {
      const res = await getUploadList({
        ...this.searchForm,
        type: this.type,
      });
      this.list = res.data;
      this.total = res.count;
      // this.amount = res.count;
    },
    handleQuickChange(day) {
      this.pickTime = "";
      this.searchForm.begin = moment()
        .subtract(day, "day")
        .startOf("day")
        .valueOf();
      this.searchForm.end = moment().valueOf();
      this.searchForm.page = 1;
      this.getData();
    },
    handlePickerChange(val) {
      // this.quickTime = "";
      this.searchForm.begin = moment(val[0]).format("YYYY-MM-DD HH:mm:ss");
      this.searchForm.end = moment(val[1]).format("YYYY-MM-DD HH:mm:ss");
      this.searchForm.page = 1;
      this.getData();
    },
    handleSearch() {
      this.searchForm.page = 1;
      this.getData();
    },
    handleTabChange(tab) {
      this.type = tab.name;
      this.amount = 0;
      this.quickTime = 15;
      this.pickTime = "";
      this.list = [];
      this.searchForm = {
        searchKey: "",
        page: 1,
        pagenum: 10,
      };
      this.getData();
    },
    handleCurrentChange(page) {
      this.searchForm.page = page;
      console.log("page >>", page);
      this.getData();
    },
    changeState(id, privace) {
      changeUploadState({
        id,
        privace: privace === "true" ? "false" : "true",
      }).then(() => {
        this.$message.success("修改成功");
        this.getData();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.member-index {
  padding: 10px 20px;
  background: #fff;
  min-height: 561px;
  ::v-deep {
    .el-form-item--small.el-form-item {
      margin-bottom: 9px;
    }
    .el-radio__input {
      display: none;
    }
  }
  .custom-select {
    width: 120px;
    margin: 0 6px;
  }
  .search-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .xf-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    p {
      margin-right: 5px;
      ::v-deep {
        span,
        .amount {
          font-size: 18px;
          margin: 0 3px;
          color: #f80000;
        }
      }
    }
  }
  ::v-deep {
    .el-pagination {
      text-align: right;
      padding: 10px 0;
    }
  }
}
</style>
